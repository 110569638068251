
import {AuthenticationProvider} from "@/modules/shared/bridges/AuthenticationProvider";
import CardInfo from "@/modules/cards/models/CardInfo";
import axios from "axios";
import {Transaction} from "@/modules/cards/models/Transaction";
import {Card} from "@/modules/cards";
import {CardRepository} from "@/modules/cards/adapter/CardRepository";


export class CardRestRepository implements CardRepository {
    private readonly auth: AuthenticationProvider;

    constructor(auth: AuthenticationProvider) {
        this.auth = auth;
    }
    //Kundenkarten/get_KundenkartenTransaktionen_DateRange
    public async getReport(reportUrl:string, reportNr:number, period:number, showPeriod:boolean): Promise<string> {
        const sid = this.auth.getAuthToken();
        const branchId = this.auth.getBranchId();
        if (sid) {

            let response:any;
            if(showPeriod) {
              let data = {
                  SID: sid,
                  Filiale: branchId,
                  ReportNr:reportNr,
                  Zeitraum: period
              }
              response = await axios.post(`${this.auth.getServiceUrl()}/Kundenkarten/${reportUrl}`,data,{ timeoutErrorMessage : 'Es ist leider zu einer Zeitüberschreitung gekommen.\nGrund dafür kann eine zu große Datenmenge sein.'} );
            } else  {
                let data = {
                    SID: sid,
                    Filiale: branchId,
                    ReportNr:reportNr,
                };
                response = await axios.post(`${this.auth.getServiceUrl()}/Kundenkarten/${reportUrl}`,data ,{ timeoutErrorMessage : 'Es ist leider zu einer Zeitüberschreitung gekommen.\nGrund dafür kann eine zu große Datenmenge sein.'});
            }

            if(response.data[reportUrl + '_response']) {
                if (response.data[reportUrl + '_response'].ServiceErrors) {
                    throw new Error(response.data[reportUrl + '_response'].ServiceErrors[0].ERRORRECOMMENDATION || response.data[reportUrl + '_response'].ServiceErrors[0].ERRORDESCRIPTION);

                } else {
                    return response.data[reportUrl + '_response'].ImageURL;
                }
            }
            return '';
        } else {
            return Promise.reject();
        }
    }

    public async cancelTransaction(transaction: Transaction): Promise<void> {
        const sid = this.auth.getAuthToken();
        const branchId = this.auth.getBranchId();
        if (sid) {
            const response = await axios.post(`${this.auth.getServiceUrl()}/Kundenkarten/Kundenkarten_BuchungStornieren`, {
                SID: sid,
                KundenkarteID: transaction.KKART_ID,
                Filiale: branchId,
                Transaktionsnummer: transaction.NUMMER,
                Stornotext: '-'
            });
            if (response.data.Kundenkarten_BuchungStornieren_response?.ServiceErrors) {
                throw new Error(response.data.Kundenkarten_BuchungStornieren_response.ServiceErrors[0].ERRORRECOMMENDATION);
            }
        } else {
            return Promise.reject();
        }
    }

    public async getCardInfo(cardId: string): Promise<CardInfo> {
        const sid = this.auth.getAuthToken();
        if (sid) {
            const response = await axios.post(`${this.auth.getServiceUrl()}/Kundenkarten/get_Kundenkarte`, {
                SID: sid,
                KundenkarteID: cardId
            });
            if (response.data.get_Kundenkarte_response?.ServiceErrors) {
                throw new Error(response.data.get_Kundenkarte_response.ServiceErrors[0].ERRORRECOMMENDATION);
            } else {
                return response.data.get_Kundenkarte_response.Kundenkarte;
            }
        } else {
            return Promise.reject();
        }
    }



    public async transferCard(cardId:string, newCardId:string): Promise<void> {
        const sid = this.auth.getAuthToken();
        const branchId = this.auth.getBranchId();
        if (sid) {
            const response = await axios.post(`${this.auth.getServiceUrl()}/Kundenkarten/Kundenkarten_KarteUebertragen`, {
                SID: sid,
                KundenkarteID: cardId,
                neueKundenkarteID: newCardId,
                Filiale: branchId
            });
            if (response.data.Kundenkarten_KarteUebertragen_response?.ServiceErrors) {
                throw new Error(response.data.Kundenkarten_KarteUebertragen_response.ServiceErrors[0].ERRORRECOMMENDATION);
            }
        } else {
            return Promise.reject();
        }
    }

    public async lockCard(card: any): Promise<void> {
        const sid = this.auth.getAuthToken();
        const branchId = this.auth.getBranchId();
        if (sid) {
            const response = await axios.post(`${this.auth.getServiceUrl()}/Kundenkarten/Kundenkarten_KarteSperren`, {
                SID: sid,
                KundenkarteID: card.ID,
                Filiale: branchId
            });
            if (response.data.Kundenkarten_KarteSperren_response?.ServiceErrors) {
                throw new Error(response.data.Kundenkarten_KarteSperren_response.ServiceErrors[0].ERRORRECOMMENDATION);
            }
        } else {
            return Promise.reject();
        }
    }

    public async unlockCard(card: Card): Promise<void> {
        const sid = this.auth.getAuthToken();
        const branchId = this.auth.getBranchId();
        if (sid) {
            const response = await axios.post(`${this.auth.getServiceUrl()}/Kundenkarten/Kundenkarten_KarteEntsperren`, {
                SID: sid,
                KundenkarteID: card.ID,
                Filiale: branchId
            });
            if (response.data.Kundenkarten_KarteSperren_response?.ServiceErrors) {
                throw new Error(response.data.Kundenkarten_KarteEntsperren_response.ServiceErrors[0].ERRORRECOMMENDATION);
            }
        } else {
            return Promise.reject();
        }
    }
    public async getCardInfoManual(cardNr: string): Promise<CardInfo> {
        const sid = this.auth.getAuthToken();
        if (sid) {
            const response = await axios.post(`${this.auth.getServiceUrl()}/Kundenkarten/get_Kundenkarte`, {
                SID: sid,
                KundenkarteID: cardNr,
                ScannerTyp: "Handeingabe"
            });
            if (response.data.get_Kundenkarte_response?.ServiceErrors) {
                throw new Error(response.data.get_Kundenkarte_response.ServiceErrors[0].ERRORRECOMMENDATION);
            } else {
                console.log(response.data.get_Kundenkarte_response.Kundenkarte)
                return response.data.get_Kundenkarte_response.Kundenkarte;
            }

        } else {
            return Promise.reject();
        }
    }

}
